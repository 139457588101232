<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row">
				<div class="col-xs-2">
					<h1 class="h2 margin-bottom-20">Corrispettivi</h1>
				</div>
				<FeeLogFilters @filter-changed="filterChanged" :default-date-filter = "dateFilter"/>
			</div>
			<FeeLogTable :feeLogs="feeLogs"/> 
		</div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
	import FeeLogTable from '@/components/FeeLog/FeeLogTable'
    import {getFeeLogs} from "../api"
	import baseMixin from '@/common/baseMixin'
	import FeeLogFilters from '@/components/FeeLog/FeeLogFilters'



    export default {
        title: "Registro dei Corrispettivi",
        name: "FeeLog",
		mixins: [baseMixin],

        components: {ContainerWithMenu,FeeLogTable,FeeLogFilters},
		data: function() {
            return {
                loading: false,
                feeLogs: {},
				dateFilter: "this-month",
				filter: {}
            }
        },
        created() {
            this.filterEnabled = this.Utils.getStartEndDateFromString(this.dateFilter);
            this.getFeeLogs(this.filterEnabled);
        },
        methods: { 
            async getFeeLogs(filter=this.filterEnabled) {
                this.loading = true;
                try {
                    this.feeLogs  = await getFeeLogs(filter);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
			filterChanged(filter){
				this.getFeeLogs(filter)
				this.filter = filter
			}
        }
    };
</script>

<template>
    <tr class="clickable-row">
        <td><a href="">{{Utils.getShortDateStringFromISO(feeLog.purchase_date_day)}}</a></td>
        <td><a href=""> <span v-if="feeLogCreator">{{StringsHelper.getCreatorSimple(feeLogCreator.id)}}</span> <span v-else>Tutti</span></a></td>
        <td><a href=""> <span v-if="feeLogPaymentMode">{{StringsHelper.getPaymentModeSimple(feeLogPaymentMode)}}</span> <span v-else>Tutti</span></a></td>
        <td class="text-right" ><a href="" class="bold">€ {{parseFloat(feeLog.price_final).toFixed(2)}}</a></td>
        <td class="text-right" ><a href="" >€ {{getFee()}}</a></td>
        <td class="text-right" ><a href="" >€ {{parseFloat(feeLog.price_net).toFixed(2)}}</a></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'

    export default {
        name: "FeeLogRow",
        mixins: [baseMixin],
        props: {
            feeLog: {
                type: Object,
                required: true
            },
            feeLogCreator:{
                type: Object
            },
            feeLogPaymentMode:{
                type: Number
            },
            
        },
        methods:{
            getFee(){
                let n = this.feeLog.price_final - this.feeLog.price_net
                return n.toFixed(2)
            }
        }
    }
</script>
